import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import { COLOR_BLACK, COLOR_WHITE } from '../constants/colors';
import { buttonStyles } from '../constants/styles';
import BookingLayout from '../components/BookingLayout';
import ErrorMessage from '../components/ErrorMessage';
import {
  FormItem, FormRow, FormSection, TextInput,
} from '../components/Form';
import { BOOKING_SUBMIT_SEARCH_FORM } from '../state/actionTypes';
import { isFindBookingInProgress } from '../state/reducers';


const SubmitRow = styled(FormRow)`
  justify-content: flex-end;
`;

const SubmitButton = styled.button.attrs({ type: 'submit' })`
  ${buttonStyles}
  margin-top: 1em;
  background-color: ${COLOR_BLACK};
  color: ${COLOR_WHITE};
`;

const validateInputs = (values) => Object.keys(values)
  .reduce((errors, fieldName) => {
    if (!values[fieldName]) {
      errors[fieldName] = 'details.errorRequired';
    } else if (fieldName === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors[fieldName] = 'details.errorInvalidEmailAddress';
    }
    return errors;
  }, {});

const FindBookingPage = ({ data, findBookingInProgress, intl: { formatMessage }, location, pageContext, submitForm }) => {
  const { languageSwitch } = pageContext;
  const pageTitle = (
    <FormattedMessage
      defaultMessage="Find your booking"
      id="booking.findBookingPageTitle"
    />
  );

  const footerData = data.allDatoCmsFooter.edges[0].node;
  const metaTitle = formatMessage({ defaultMessage: 'Find your booking', id: 'booking.findBookingPageTitle' });
  return (
    <BookingLayout
      footerData={footerData}
      isPostBooking
      languageSwitch={languageSwitch}
      location={location}
      metaTitle={metaTitle}
      title={pageTitle}
    >
      <ErrorMessage />
      <Formik
        initialValues={{
          confirmationNumber: '',
          email: '',
        }}
        onSubmit={(values) => { submitForm(values); }}
        render={({
          errors,
          touched,
        }) => (
          <Form>
            <FormSection>
              <FormRow>
                <FormItem>
                  <TextInput
                    error={touched.confirmationNumber && errors.confirmationNumber}
                    id="confirmationNumber"
                    label="booking.confirmationNumber"
                    required
                  />
                </FormItem>
              </FormRow>
              <FormRow>
                <TextInput
                  autoComplete="email"
                  error={touched.email && errors.email}
                  id="email"
                  label="details.personalDetailsEmailAddress"
                  required
                  type="email"
                />
              </FormRow>
            </FormSection>
            <SubmitRow>
              <SubmitButton disabled={findBookingInProgress}>
                <Choose>
                  <When condition={findBookingInProgress}>
                    <FormattedMessage
                      defaultMessage="Searching..."
                      id="booking.searching"
                    />
                  </When>
                  <Otherwise>
                    <FormattedMessage
                      defaultMessage="Search"
                      id="booking.search"
                    />
                  </Otherwise>
                </Choose>
              </SubmitButton>
            </SubmitRow>
          </Form>
        )}
        validate={validateInputs}
      />
    </BookingLayout>
  );
};

export const query = graphql`
  query FooterByLocale($locale: String!) {
    allDatoCmsFooter(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...footerFields
        }
      }
    }
  }
`;

FindBookingPage.propTypes = {
  data: PropTypes.object.isRequired,
  findBookingInProgress: PropTypes.bool,
  intl: intlShape,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  findBookingInProgress: isFindBookingInProgress(state),
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: (payload) => dispatch({ type: BOOKING_SUBMIT_SEARCH_FORM, payload }),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FindBookingPage));
